﻿@import "colours.less";
@import "sizes.less";
@import "mixins.less";


@font-face {
    font-family: 'Gotham';
    src: url('/styles/webfonts/Gotham-Book.eot');
    src: url('/styles/webfonts/Gotham-Book.eot?#iefix') format('embedded-opentype'), url('/styles/webfonts/Gotham-Book.woff2') format('woff2'), url('/styles/webfonts/Gotham-Book.woff') format('woff'), url('/styles/webfonts/Gotham-Book.ttf') format('truetype'), url('/styles/webfonts/Gotham-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('/styles/webfonts/Gotham-Bold.eot');
    src: url('/styles/webfonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'), url('/styles/webfonts/Gotham-Bold.woff2') format('woff2'), url('/styles/webfonts/Gotham-Bold.woff') format('woff'), url('/styles/webfonts/Gotham-Bold.ttf') format('truetype'), url('/styles/webfonts/Gotham-Bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('/styles/webfonts/Gotham-Black.eot');
    src: url('/styles/webfonts/Gotham-Black.eot?#iefix') format('embedded-opentype'), url('/styles/webfonts/Gotham-Black.woff2') format('woff2'), url('/styles/webfonts/Gotham-Black.woff') format('woff'), url('/styles/webfonts/Gotham-Black.ttf') format('truetype'), url('/styles/webfonts/Gotham-Black.svg') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('/styles/webfonts/Gotham-Light.eot');
    src: url('/styles/webfonts/Gotham-Light.eot?#iefix') format('embedded-opentype'), url('/styles/webfonts/webfontsGotham-Light.woff2') format('woff2'), url('/styles/webfonts/Gotham-Light.woff') format('woff'), url('/styles/webfonts/Gotham-Light.ttf') format('truetype'), url('/styles/webfonts/Gotham-Light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/*@font-face {
    font-family: 'Gotham';
    src: url('/styles/webfonts/Gotham-Medium.eot');
    src: url('/styles/webfonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'), url('/styles/webfonts/Gotham-Medium.woff2') format('woff2'), url('/styles/webfonts/Gotham-Medium.woff') format('woff'), url('/styles/webfonts/Gotham-Medium.ttf') format('truetype'), url('/styles/webfonts/Gotham-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
}*/


html {
    overflow-y: scroll;
}

body {
    a {
        color: @linkColour;
        text-decoration: underline;

        &[href^='tel:'] {
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
            color: unset;
        }

        &[role=button] {
            display: inline-block;
        }
    }
}

.view-all-news-button-container {
    text-align: center;
}

body, input, select, textarea {
    font-family: "Gotham", Arial, Helvetica, sans-serif;
    line-height: 1.3;
    color: @bodyTextColour;
    font-size: @fontSizeNormal;
}

input, select, textarea {
    -webkit-border-radius: 0;
    outline: none;
}

input:focus, button:focus {
    outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
select::-ms-expand {
    display: none;
}

main, footer {
    line-height: 1.3;

    p, ul, ol, h1, h2, h3, h4, h5, h6 {
        margin: 0 0 .5em;
    }

    p {
        line-height: 1.3em;
        margin: 0 0 .8em;
    }
}

button, input[type=submit], input[type=button], .button, a[role=button] {
    font-size: 1.15rem;
    font-weight: bold;
    color: @buttonTextColour;
    background-color: @buttonColour;
    border: none;
    border-radius: 100px;
    padding: 11px 25px 11px;
    transition: transform @transitionSpeed;
    transition-delay: @transitionDelay;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &.rounded {
        border-radius: 50px;
    }

    &.donate {
        background-color: @donateButtonBackground;
    }

    &.arrow {
        padding-right: 45px;
        background-image: url(../images/arrow-right.png);
        background-size: 18px auto;
        background-repeat: no-repeat;
        background-position: right 15px center;
    }

    &:hover, &:focus {
        transform: @buttonHoverTransform;
    }
}

input[type=submit], button[type=submit] {
    background-color: @submitButtonBackground;
}
button{
    font-family:Gotham;
}

nav {
    h3 {
        font-weight: bold;
        font-size: 1.31rem;
        text-transform: uppercase;
        line-height: 1.5em;
    }

    p {
        line-height: 2.1em;
    }
}

main {
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        line-height: 1.2em;
    }

    h1 {
        font-weight: 900;
        font-size: 2.4rem;
    }

    h2 {
        font-weight: 900;
        font-size: @fontSizeMassive;
    }

    @media screen and (min-width: @tabletWidth) {
        h1 {
            font-size: @fontSizeColossal;
        }

        h2 {
            font-size: @fontSizeEnormous;
        }
    }

    h3 {
        font-size: @fontSizeMassive;
    }

    h4 {
        font-size: @fontSizeHuge;
    }

    h5 {
        font-size: @fontSizeBig;
    }

    h6 {
        font-size: 1.1rem;
    }

    q {
        .surroundWithQuotes();
    }

    legend {
        font-weight: 900;
        font-size: @fontSizeEnormous;
    }

    strong {
        font-weight: bold !important;
    }

    em {
        font-style: italic;
    }

    ul {
        list-style-type: disc;
        overflow: visible;
    }

    ol {
        list-style-type: decimal;
        overflow: visible;
    }

    li {
        margin-left: 2em;
        margin-bottom: .5em;
        list-style-type: disc;
    }

    figure {
        margin-bottom: 1.5em;
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    figcaption {
        font-style: italic;
        font-size: 1.1em;
    }

    p {
        font-size: @fontSizeNormal;
    }

    .hero {
        p {
            font-size: 1.32rem;
        }
    }

    form {
        p.info {
            font-size: 0.82rem;
        }
    }


    iframe, img {
        max-width: 100%;
    }

    img {
        height: auto !important;
    }


    img[style*='float: left'] {
        margin: 4px 1em .5em 0;
    }

    img[style*='float: right'] {
        margin: 4px 0 .5em 1em;
    }

    .validation-summary-valid {
        color: @validationTextColour;
        display: none;
    }

    .validation-summary-errors {
        color: @validationTextColour;

        ul {
            display: none;
        }
    }

    .RTE {
        h2, h3, h4, h5 {
            font-weight: bold;
        }

        h2 {
            font-size: 1.4rem;
        }

        h3 {
            font-size: 1.3rem;
        }

        h4 {
            font-size: @fontSizeBig;
        }

        h5 {
            font-size: 1.1rem;
        }

        ul, ol {
            margin-left: 1rem;
            margin-bottom:1rem;
        }

        li {
            list-style-type: disc;
            margin-left: 0.5rem;
        }

        ol > li {
            list-style-type: decimal;
        }

        a:not([role=button]){
            color:@bodyLinkColour;
        }
    }
}

.clearer {
    clear: both;
}

/* Grouped validation messages - only show the first error message */
.groupVal {
    .field-validation-error {
        display: block;

        & ~ .field-validation-error {
            display: none;
        }
    }
}


input.invisible {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    padding: 0 !important;
    min-height: 0 !important;
    min-width: 0 !important;
    margin: 0 !important;
    border: none !important;
    position: absolute;
}

.noWrap {
    display: inline-block;
    white-space: nowrap;
}

form {
    input[type=text], input[type=search], input[type=number], input[type=date], input[type=email], input[type=tel], input[type=password], textarea, select {
        border: none;
        border-bottom: 3px solid @black;
        background: none;
        padding: 0.5em 0;
        position: relative;
        z-index: 1;
        width: 215px;
        max-width: 466px;
        -webkit-appearance: none;

        &.narrow {
            max-width: 215px;
        }


        &.inline {
            width: 215px;
        }

        &.full {
            width: 100%;
        }

        &::-ms-clear {
            display: none;
        }
    }

    textarea {
        overflow: auto;
        width: 100%;
    }

    div.dynalabel {
        position: relative;
        margin: 1rem 0 0;
        padding-bottom: 0.5rem;
        box-sizing: border-box;

        label {
            position: relative;
            top: 25px;
            display: block;
            z-index: 0;
            transition-property: top, font-size, color;
            transition-duration: @transitionSpeed;
            transition-delay: @transitionDelay;
            cursor: text;
        }

        &.active {
            label {
                top: 5px;
                font-size: 0.8rem;
                color: @activeLabelColour;
            }
        }

        &.datelabel {
            label {
                background-color: @white;
                z-index: 100;
                margin-bottom: -5px;
                padding-bottom: 3px;
            }
        }

        input, select, textarea {
            box-sizing: border-box;
        }
    }

    div.highLabel {
        margin-top: 2rem;

        label {
            display: block;
        }
    }

    div.autowidth {
        display: inline-block;
        max-width: 466px;

        label {
            padding-right: 30px;
        }

        input[type=text], input[type=tel], input[type=email], input[type=password], select, textarea {
            max-width: none;
            width: 100%;
            min-width: 215px;
        }
    }

    div.inline {
        display: inline-block;
        margin-right: 40px !important;
        vertical-align: top;

        &.last {
            margin-right: 0 !important;
        }
    }

    label.radio {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        min-height: 20px;
        margin-top: 25px;
        /*text-indent: -10px;*/
        &::before {
            position: absolute;
            top: 1px;
            left: 0;
            border-radius: 50%;
            content: "";
            border: 2px solid @black;
            background: #fff;
            width: 26px;
            height: 26px;
        }

        &::after {
            opacity: 1;
            border: 10px solid @buttonColour;
            border-radius: 50%;
            width: 0;
            height: 0;
            position: absolute;
            top: 6px;
            left: 5px;
            -ms-zoom: 1;
            zoom: 1;
            content: "";
            opacity: 0;
            background-color: @buttonColour;
        }

        &.selected::after {
            opacity: 1;
        }

        input[type=radio] {
            opacity: 0;
            margin-left: -10px;
        }
    }

    label.checkbox {
        position: relative;
        padding-left: 45px;
        padding-top: 6px;
        padding-bottom: 5px;
        min-height: 20px;
        text-indent: -10px;
        margin-top: 22px;
        display: block;

        &::before {
            position: absolute;
            top: 3px;
            left: 0;
            content: "";
            border: 2px solid @black;
            border-radius: 0;
            width: 26px;
            height: 26px;
            border-radius: 3px;
        }

        &::after {
            border-style: solid;
            border-color: @white;
            border-width: 0 0 3px 3px;
            width: 11px;
            height: 5px;
            position: absolute;
            top: 12px;
            left: 8px;
            -ms-zoom: 1;
            zoom: 1;
            -moz-transform: rotate(-44deg);
            -ms-transform: rotate(-44deg);
            -o-transform: rotate(-44deg);
            -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg);
            content: "";
            opacity: 0;
        }

        &.selected::before {
            background-color: @buttonColour;
            -moz-box-shadow: inset 0 0 0 2px @white;
            -webkit-box-shadow: inset 0 0 0 2px @white;
            box-shadow: inset 0 0 0 2px @white;
        }

        &.selected::after {
            opacity: 1;
        }

        input[type=checkbox] {
            opacity: 0;
            margin-left: -10px;
        }
    }

    &.inverted {
        color: @white;

        input[type=text], input[type=search], input[type=email], input[type=password], textarea {
            border-bottom: 3px solid @white;
            color: @white;
        }

        > div:not(.radios), > fieldset > div:not(.radios) {
            &.active {
                label {
                    color: @white;
                    opacity: .7;
                }
            }
        }
    }

    div.radios {
        margin: 2rem 0;

        label:not(.radio) {
            display: block;
            margin-bottom: 0.6rem;
        }

        label.radio {
            top: 10px;
            margin-right: 2rem;
        }
    }

    div.checkbox {
        margin: 2rem 0;
    }
}

/*Used for textarea autoresizing*/
.hiddendiv {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre-wrap;
    width: 500px;
    min-height: 1.5em;
    font-weight: normal;
    font-size: @fontSizeNormal;
    padding: 0.5em 0;
    word-wrap: break-word;
    box-sizing: border-box;
}

.todo {
    &:before {
        content: 'TO DO: '
    }

    font-weight: bold;
    color: #fff;
    background-color: #208f00;
    padding: 4px 12px 5px;
    margin: .6em 0;
    border-radius: 20px;
    transform: rotate(-4deg);
    display: inline-block;
    position: relative;
    left: -20px;
    z-index: 1000;
}

.hidden {
    display: none !important;
}


.desktopFlex {
    @media screen and (min-width: @desktopWidth) {
        display: flex;
    }
}

.raggedBottom {
    align-items: flex-start;
}

.none {
    height: 1px;
}

.noFlex {
    display: block !important;
}

.reverseFlex {
    flex-direction: row-reverse;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.centred {
    .centreUsingMargins();
    text-align: center;
}

a.videoThumbnail, a.video {
    > span {
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;
        left: -1000px;
        text-indent: -1000px;
        opacity: 0;
    }
}
