﻿@import "colours.less";
@import "mixins.less";

body {
    .spacer {
        clear: both;
    }

    .italic {
        font-style: italic;
    }

    section > section {
        &.highlight {
            background-color: @highlightedSectionBackgroundColour !important;
            border-top: 2px solid @black;
            margin-top: 4.5rem;
            padding-bottom: 1.4rem;

            h2 {
                .bordered();
                .blackBordered();
            }
        }
    }

    section.mb-20 {
        margin-bottom: 20px;
    }

    section.mt-20 {
        margin-top: 20px;
    }

    section.thumbLinks {
        display: flex;
        flex-flow: row wrap;
        margin-left: -7px;
        margin-right: -7px;

        article {
            .linkBox();
            box-shadow: @boxShadowStandard;
            .textWithBackground();

            a {
                color: @white;
                text-decoration: underline;
            }

            .overlay {
                .overlay(@thumbLinkOverlayColour);
                transition: background-color @transitionSpeed;
                transition-delay: @transitionDelay;
            }

            &:not(.noHover) {
                &:hover, &:focus {
                    .overlay {
                        background-color: @thumbLinkOverlayHoverColour;
                    }
                }
            }
        }

        &.col-4 {
            article {
                flex: 1 1 22%;
                min-width: auto;
            }
        }

        @media screen and (min-width: @desktopWidth) {

            article {

                &:hover, &:focus {
                    box-shadow: rgba(0, 0, 0, 0.3) 0 0 9px;
                }
            }
        }
    }

    section.downloads {
        background-color: #EDEDED;
        padding-top: 45px;
        padding-bottom: 20px;

        section.thumbLinks {
            article {

                .overlay {
                    background-color: #127EB6;
                }

                &:not(.noHover) {
                    &:hover, &:focus {
                        .overlay {
                            background-color: #127EB6;
                        }

                        span.more {
                            background-color: #fff;
                            color: #127EB6;
                            border-color: #fff;
                            background-image: url(../images/arrow-right-blue.png);
                        }
                    }
                }
            }
        }
    }

    .titled + .downloads {
        padding-top: 60px;
        margin-top: -78px;
        margin-bottom: 20px;
    }

    section.infographic-banner {
        background-color: #127EB6;
        padding-top: 30px;
        padding-bottom: 30px;

        .inner {
            max-width: 85%;
            display: flex;
            margin: 0 auto;

            .col-1 {
                box-sizing: border-box;
                flex: 1 1 40%;

                .img {
                    display: block;
                    margin: 0 auto;
                }
            }

            .col-2 {
                box-sizing: border-box;
                flex: 1 1 60%;
            }

            h4 {
                font-size: 21px;
                line-height: 30px;
                color: #fff;
                font-family: "Gotham";
                font-weight: 600;
                position: relative;
                top: 50%;
                padding-left: 30px;
                transform: translateY(-50%)
            }

            @media screen and (max-width: @tabletWidth) {
                display: block;
                max-width: 100%;

                .col-1,
                .col-2 {
                    flex: 1 1 100%;
                }

                h4 {
                    top: 0;
                    transform: none;
                    padding-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    section.three-step-banner {
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;

        .wrapper {
            position: relative;
            box-sizing: border-box;
            display: block;
            max-height: 290px;
            min-height: 290px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            .page {
                min-height: 290px;
                position: relative;
                top: 0;
            }

            @media screen and (max-width: @tabletWidth) {
                max-height: 100%;
                padding-top: 25px;
                padding-bottom: 25px;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.6;
                background-color: #002664;
                z-index: 1;
            }

            .steps {
                display: flex;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                transform: translate(-50%, -50%);
                width: 100%;

                .step {
                    box-sizing: border-box;
                    flex: 1 1 33%;


                    img {
                        display: block;
                        margin: 0 auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .img {
                        display: block;
                        width: 100%;
                        min-height: 107px;
                        max-height: 107px;
                        position: relative;
                    }

                    h5 {
                        display: block;
                        color: #fff;
                        max-width: 80%;
                        font-size: 16px;
                        font-family: "Gotham";
                        font-weight: 500;
                        line-height: 21px;
                        text-align: center;
                        margin: 15px auto 0;
                    }
                }

                @media screen and (max-width: @tabletWidth) {
                    display: block;
                    position: relative;
                    top: 0;
                    left: auto;
                    transform: none;

                    .step {
                        display: block;
                        margin-top: 25px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }

    section.simpleLinks {
        margin-top: 0.6rem;
        margin-left: -7px;
        margin-right: -7px;

        &, > .articles {
            display: flex;
            flex-flow: row wrap;
        }

        article {
            .simpleLinkBox();
            background: none !important;
        }

        &.whiteBtns {
            article {
                span.more {
                    background-color: @white;
                }
            }
        }
    }

    section.contactDetails {
        text-align: center;
        margin: 35px 15px;
        padding: 125px 20px 30px;
        box-shadow: @boxShadowStandard;
        background: @white url(../images/phone-bk.svg) no-repeat center 35px;
        background-size: 43px 76px;
        box-sizing: border-box;

        h3 {
            font-size: 1.3rem;
        }

        p {
            font-size: @fontSizeBig;
            font-weight: bold;
            margin-bottom: 0;
        }

        .telephone {
            display: inline-block;
            background-color: @telephoneHighlightColour;
            font-weight: 900;
            font-size: 1.3rem;
            padding: 8px 9px;
            margin: 1.1rem 0;

            a {
                color: @white;
            }
        }

        .openingTimes {
            font-weight: normal;
            font-size: @fontSizeNormal;
            margin-bottom: 0.5em;
        }

        .btn-chat {
            display: table;
            background-color: #022664;
            font-size: 16px;
            line-height: 28px;
            color: #fff;
            font-weight: bold;
            padding: 10px 20px;
            text-align: center;
            margin: 20px 0 20px;
            padding-top: 3px;
            cursor: pointer;
            text-decoration: none;

            @media screen and (max-width: 599px) {
                margin: 20px auto 20px;
            }

            img {
                display: inline-block;
                width: 28px;
                top: 10px;
                position: relative;
                margin-right: 5px;
            }
        }

        &.home {

            @media screen and (min-width: @tabletWidth) {
                background-size: 70px 130px;
                background-position: left 60px center;
                padding: 30px 20px 30px 190px;

                h3 {
                    font-size: 1.8rem;
                    margin-bottom: .5rem;
                }

                .text {
                    text-align: left;
                    padding-left: 50px;
                    border-left: @dividerLine;
                }

                .openingTimes {
                    br {
                        display: none;
                    }
                }
            }

            @media screen and (min-width: @desktopWidth) {
                margin: -60px auto 80px;
                max-width: 905px;
                padding-left: 220px;
                background-position: left 75px center;
            }

            .btn-chat-img {
                display: none;
            }
        }

        &.servicePage {
            .btn-chat {
                display: none;
            }

            .btn-chat-img {
                img {
                    margin: 20px auto 20px;
                    max-width: 154px;
                }
            }
        }
    }
    /*list of case studies taken from the case study repository*/
    section.caseStudies {

        > .page {
            > p {
                max-width: 720px;
            }
        }

        article {
            .caseStudyBox();

            @media screen and (max-width: @smallPhoneWidth) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        article.teamMember {
            padding-bottom: 25px;

            h4 {
                font-weight: 300;
            }

            h3, p {
                max-width: 350px;
                .centreUsingMargins();
            }

            h4 {
                max-width: 150px;
                .centreUsingMargins();
            }
        }

        @media screen and (min-width: @desktopWidth) {
            .articles {
                display: flex;
                flex-flow: row wrap;
                margin-left: -7px;
                margin-right: -7px;
            }

            p + .articles {
                margin-top: 2rem;
            }

            article {
                padding-left: 15px;
                padding-right: 15px;
                max-width: none;
            }

            article .videoThumbnail {
                margin-top: -131px;
                margin-bottom: -14px;
            }
        }
    }
    /*Single bespoke case study, defined at page-level*/
    article.caseStudy {
        padding-bottom: 30px;

        .imageOrVideo {
            margin-bottom: 0.8rem;
        }

        @media screen and (min-width: @tabletWidth) {
            .imageOrVideo {

                a:after {
                    left: 44.4%;
                }
            }

            .text {
                max-width: 76%;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            .imageOrVideo {
                float: right;
                width: 55%;
                height: auto;
                margin-top: -40px;
            }

            .text {
                margin-top: 50px;
                width: 40%;
            }

            &.reversed {
                .imageOrVideo {
                    float: left;
                }

                .text {
                    float: right;
                }
            }
        }
    }

    .imageOrVideo {
        a {
            display: block;
            position: relative;
            .videoIndicator(@videoIndicatorFillColour);
        }
    }

    section.howYouCanHelp, section.subPages, section.volunteerResults {
        .articles {
            margin-top: 50px;
            margin-left: -7px;
            margin-right: -7px;
        }

        button.subpages-load-more {
            margin-top: 15px;
        }

        article {
            .simpleImageLinkBox();

            h3 {
                display: block;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            .articles {
                display: flex;
                margin-top: 0;
                flex-flow: row wrap;
            }

            article {
                margin-top: 15px;
            }
        }
    }

    section.howYouCanHelp {

        .articles article > span {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    section.volunteerResults {
        margin-bottom: 70px;

        article {
            max-width: 534px;

            @media screen and (min-width:@tabletWidth) and (max-width: (@desktopWidth - 1)) {
                .centreUsingMargins();
            }

            @media screen and (min-width:@desktopWidth) {
                max-width: 31.9%;
                flex-basis: 25%;
            }
        }
    }

    section.subPages {
        margin-bottom: 45px;
    }

    section.hero {

        .outer, .inner {
            display: none;
        }

        a {
            color: @white;
            text-decoration: none;
        }

        .inner {
            position: relative;
            .arrowButton();

            span.more {
                position: inherit;
                top: 20px;
            }

            @media screen and (max-width: @mediumTabletWidth) {
                span.more {
                    background: url(/images/hero-arrow-right-tablet.svg) no-repeat;
                    display: inline-block;
                    width: 16.28pt;
                    height: 11.45pt;
                    border: none;
                    top: 20px;
                }
            }
        }

        #mainContentLink {
            display: block;
            z-index: 10;
            width: 100px;
            height: 49px;
            margin: -20px auto 0;
            color: transparent;
            text-indent: -10000px;
            background: transparent url(../images/home-down.svg) no-repeat center bottom;
            background-size: auto 49px;
            position: relative;
            top: 30px;

            &:hover {
                animation: bounce 1s infinite ease;
            }
        }

        .mobImage {
            height: 61vw;
            .cover();
        }

        .mobContent {
            padding: 1.6rem 20px 1rem;
            background-color: @faint;
            margin-bottom: 2rem;

            h1 {
                .bordered();
                //iOS border tweak
                @supports

                (-webkit-overflow-scrolling: touch) {
                    > span {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            p {
                font-size: 1.1rem;
                color: #000000;
            }

            span.more {
                background: @red;
                max-width: 350px;
                border-color: @red;
                padding: 10px 20px;
                border-radius: 20px;
                display: inline-block;
            }
        }

        .location, .date, .type {
            display: block;
            margin-left: 10px;
            margin-bottom: 0;
            font-weight: bold;
        }

        .location {
            background-position-x: 5px;
            padding-left: 41px;
        }

        @media screen and (min-width: @tabletWidth) {
            .mobImage, .mobContent {
                display: none;
            }

            .outer {
                .cover();
                padding: 20px 20px;
                color: @heroTextColour;
                height: 370px;
                min-height: 370px;
                display: table;
                width: 100%;
                box-sizing: border-box;
                margin-bottom: 2em;
            }

            .inner {
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                padding: 1em 50px;

                h1 {
                    margin-top: 0.2em;
                    font-size: @fontSizeColossal;
                    .bordered();
                }

                p {
                    max-width: 560px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &.thankyou {
                .inner {
                    h1 {
                        max-width: 610px;
                    }
                }
            }

            .location, .date, .type {
                margin-left: 0;
                margin-bottom: .6rem;
                padding-left: 65px;
            }

            .location {
                background-image: url(../images/location-marker-wt.svg);
                background-position-x: 5px;
                height: auto;
            }

            .date {
                background-image: url(../images/calendar-wt.svg);
                height: auto;
            }

            .type {
                background-image: url(../images/local_offer_black_24dp.svg);
            }
        }

        @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
            .inner {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        //OS X Safari - can't have nested media queries
        @media screen and (min-width: @tabletWidth) and (-webkit-max-device-pixel-ratio:1) and (min-color-index:0) {
            @supports

            (-webkit-appearance:none) and (stroke-color:transparent) {
                .inner {
                    h1 {
                        padding: 2px 0;

                        > span {
                            outline: 3px solid @white;
                            background-color: @black;
                            display: inline;
                            padding: 0.1rem 0.6rem;
                            box-decoration-break: clone;
                            -webkit-box-decoration-break: clone;

                            > span {
                                border: none;
                                background-color: transparent;
                                position: static;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }


        & + .contact {
            margin-top: 100px;
        }
    }

    section.donate {
        background-size: cover;
        background-position: center center;

        .inner {
            color: white;
            text-align: center;
            padding: 40px 30px 30px;
            max-width: 720px;

            p {
                max-width: 535px;
                margin-left: auto;
                margin-right: auto;

                &.link {
                    margin-top: 30px;

                    a {
                        .donateButton()
                    }
                }
            }

            @media screen and (max-width: (@tabletWidth - 1)) {
                h3 {
                    font-size: 1.3rem;
                }
            }
        }

        @media screen and (min-width: @tabletWidth) {
            margin-top: 20px;
        }

        @media screen and (min-width: @desktopWidth) {
            margin-bottom: 85px;
        }
    }

    section.newsletterSignUp {
        padding-top: 2rem;

        @media screen and (min-width: @desktopWidth) {
            flex: 1 1 50%;
            padding-top: 1rem;
            margin-right: 20px;
        }
    }

    section.newsletterSignUp {

        article {
        }

        form {
            margin-bottom: 3rem;

            fieldset {
                border: none;
                padding: 0;
                margin: 0;

                legend {
                    font-size: @fontSizeMassive;
                    font-weight: bold;
                    margin-bottom: 1rem;
                }
            }
        }

        .footer p {
            font-size: @fontSizeTiny;
        }
    }

    section.latestNews {
        padding-top: 2rem;

        a {
            text-decoration: none;
        }

        article {
            .newsArticleWithThumbnail();
        }

        @media screen and (min-width: @desktopWidth) {
            flex: 1 1 50%;
            padding-bottom: 3rem;
            padding-top: 1rem;
            margin-left: 20px;
        }
    }

    section.splitContent {
        padding-top: 40px;
        margin-bottom: 50px;

        article {
            flex: 1 1 50%;
            position: relative;
            .textWithBackground();
            padding: 30px 30px 20px;
            margin-bottom: 20px;

            .overlay {
                .overlay(@thumbLinkOverlayColour);
            }

            .text {
                position: relative;
                z-index: 10;

                h2 {
                    font-size: 1.3rem;
                    font-weight: bold;
                    margin-bottom: 1rem;
                }
            }
        }

        @media screen and (min-width: @tabletWidth) {
            padding-top: 0;
        }

        @media screen and (min-width: @desktopWidth) {
            padding-top: 0;
            margin-bottom: 25px;

            .inner {
                margin-left: -7px;
                margin-right: -7px;
                display: flex;
            }

            article {
                margin: 0 7px 20px;
                padding: 40px 40px 40px;
            }
        }
    }

    section#latestNews {
        &.highlight {
            padding-bottom: 55px;
        }
    }

    section.latestNewsBlocks {
        @media screen and (min-width: @desktopWidth) {
            display: flex;
            flex-wrap: wrap;

            &.newsIndexResults {
                margin-top: 0;
            }
        }

        margin: 50px -10px 5px;

        a {
            text-decoration: none;
        }

        article {
            .newsArticleBlock();

            @media screen and (min-width: @desktopWidth) {
                margin: 0 10px 17px;

                .text {
                    padding: 20px 172px 5px 20px;

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        line-height: 1.3;
                        max-height: 3.9;
                    }
                }
            }
        }
    }

    article.quote {
        margin-top: 45px;
        margin-bottom: 1.5rem;
        background-color: @faint;
        padding: 35px 25px 10px;
        position: relative;

        q {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
            margin-bottom: 0.5rem;
        }

        &:before {
            display: block;
            position: absolute;
            content: '';
            width: 57px;
            height: 50px;
            top: -22px;
            left: 11px;
            background: transparent url(../images/quote-bubble.svg) no-repeat 0 0;
            background-size: 100% 100%;
        }

        @media screen and (min-width: @tabletWidth) {
            margin-left: 50px;
            padding-left: 40px;
            padding-top: 25px;

            &:before {
                width: 72px;
                height: 63px;
                left: -50px;
                top: -19px;
            }
        }
    }
    /*contact form*/
    section.contact {
        margin-top: 35px;
        padding-bottom: 20px;
        background-image: linear-gradient(to right, @globalBackgroundOverlayColour, @thumbLinkOverlayColour), url(../images/hands-and-medal.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        #frmContactMessage, #contactThanks {
            flex-basis: 55%;
            margin-top: -55px;
            margin-left: auto;
            margin-right: auto;
            max-width: 535px;
            box-sizing: border-box;
        }

        #frmContactMessage {
            fieldset {
                border: none;
                padding: 0;
                margin: 0;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            margin-top: 75px;
        }

        .RTE a {
            color: @white;
        }


        @media screen and (min-width: @desktopWidth) {
            margin-top: 15px;

            form {
                margin-right: 60px;

                div.inline {
                    width: 44%;

                    .inline {
                        width: 100%;
                    }
                }
            }

            #frmContactMessage, #contactThanks {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 4rem;
            }

            section.bodyText {
                max-width: none;
                margin-right: 90px;
            }
        }

        .contactDetails {
            flex-basis: 45%;
            background-image: url(../images/phone-wt.svg);
            background-color: transparent;
            background-size: auto 95px;
            padding-top: 155px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            color: @white;
            box-shadow: none;
            max-width: 450px;

            h3 {
                font-size: 1.8rem;
            }

            .telephone {
                font-size: 1.8rem;
            }

            &.global {
                @media screen and (min-width: @tabletWidth) {
                    background-size: auto 123px;
                    background-position: center top;
                    max-width: 580px;

                    p.intro {
                        font-size: @fontSizeMassive;
                    }

                    .openingTimes br {
                        display: none;
                    }
                }
            }

            .btn-chat-img {
                img {
                    width: 100%;
                    max-width: 210px !important;
                    margin-top: 15px;
                }
            }

            @media screen and (min-width: @desktopWidth) {
                background-size: auto 123px;
                background-position: center top;

                .openingTimes br {
                    display: none;
                }
            }
        }

        .bodyText {
            flex-basis: 45%;
            margin-top: 3rem;
            margin-bottom: 2rem;
            color: @white;
            box-shadow: none;
            max-width: 580px;
            margin-left: 0;
            margin-right: 0;


            h2 {
                font-size: @fontSizeMassive;
                font-weight: bold;
            }

            h3 {
                font-size: @fontSizeHuge;
                font-weight: bold;
            }

            h4 {
                font-size: @fontSizeBig;
                font-weight: bold;
            }

            a[role=button] {
                background-color: @submitButtonBackground;
            }
        }
    }

    section.newsIndexResults {
        @media screen and (min-width: @tabletWidth) {
            article {
                overflow: hidden;

                .image img {
                    width: 207px;
                    height: 207px;
                }

                .text {
                    padding: 30px 242px 10px 40px;

                    h3 {
                        font-size: 1.4rem;

                        & + p {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }

    section.applicationForm {
        margin-bottom: 6rem;
    }

    section.searchForm {
        margin-bottom: 35px;

        form {
            h4 {
                background: transparent url(../images/find-arrow.svg) no-repeat left center;
                background-position: -17px -14px;
                padding-left: 48px;
                font-size: @fontSizeMassive;
                font-weight: 900;
                padding-bottom: 1rem;
                margin-bottom: 1.2rem;
            }

            background-color: @branchfinderFormBackgroundColour;
            padding: 25px 30px;

            .btns {
                margin-top: 1.8rem;

                button {
                    background-color: @white;
                    color: @lightBlue;
                    background-image: url(../images/arrow-right-blue.png)
                }
            }

            @media screen and (min-width: @desktopWidth) {
                margin-right: 382px;
            }
        }
    }

    section.siteSearchResults {
        max-width: 590px;

        article {
            margin-bottom: 2.5rem;

            h3 {
                font-size: @fontSizeHuge;

                > .lastWord {
                    display: inline-block;
                    background: transparent url(../images/arrow-right-black.png) no-repeat right center;
                    background-size: 18px auto;
                    padding-right: 28px;
                }
            }

            h6 {
                text-transform: uppercase;
                color: #022664;
                font-size: 1rem;
            }

            a {
                text-decoration: none;
            }
        }
    }

    section.searchResults {
        h2 {
            font-size: @fontSizeMassive;
            font-weight: bold;
        }

        h3 {
            font-size: @fontSizeHuge;
            font-weight: bold;
        }

        h4 {
            font-size: @fontSizeBig;
        }

        article {
            padding: 20px 30px;
            box-shadow: @boxShadowStandard;

            > p {
                clear: both;
                margin: 1.5rem 0;
            }

            > div {
                margin-bottom: 1rem;
            }

            @media screen and (min-width: @tabletWidth) {
                overflow: hidden;

                > div {
                    width: 45%;
                    float: left;
                }
            }

            @media screen and (min-width: @desktopWidth) {
                margin-right: 382px;
            }
        }
    }

    section.volunteerSearchForm {
        margin-bottom: 35px;

        form {
            background-color: @volunteerSearchFormBackgroundColour;
            padding: 25px 20px 30px;

            .imageOrVideo {
                margin-top: 0;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            h3 {
                font-size: @fontSizeMassive;
                font-weight: 900;
                padding-bottom: 1rem;
                margin: 2.5rem 0 1.5rem;
            }


            .btns {
                margin-top: 2.8rem;

                button {
                    background-color: @lightBlue;
                }
            }

            @media screen and (min-width: @desktopWidth) {
                display: flex;
                flex-direction: row-reverse;
                padding: 35px 30px 30px;

                .text {
                    flex: 1 1 50%;
                    margin-right: 40px;
                    margin-top: 0;

                    h3 {
                        margin-top: 0;
                    }
                }

                .imageOrVideo {
                    flex: 1 1 50%;
                    max-width: 505px;
                }
            }
        }
    }

    section.motivator {
        margin-top: 60px;

        &.highlight {
            border-top: none;
            margin-top: 120px;

            article .image > img {
                margin-top: -60px;
            }
        }

        article {
            .image img {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
            }

            .text {
                max-width: 530px;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            article {
                display: flex;

                .text {
                    flex: 1 1 40%;
                    margin-left: 40px;
                    padding-top: 60px;
                }

                .image {
                    flex: 1 1 60%;
                    max-width: 628px;
                }

                &.highlight {
                    margin-top: 120px;

                    article > img {
                        margin-top: -60px;
                    }
                }
            }
        }
    }

    .socialBlocks {
        margin-top: 45px;

        article {
            .simpleImageLinkBox();
            text-align: center;
            max-width: 520px;
            .centreUsingMargins();
            background: @white url() no-repeat center bottom 20px;
            padding-bottom: 60px;
            margin-top: 15px;


            p {
                max-width: 410px;
                .centreUsingMargins();

                &.name {
                    margin-bottom: 1px;
                    font-size: @fontSizeTiny;
                    font-weight: 900;
                    color: @deepBlue;
                }

                &.date {
                    font-size: @fontSizeTiny;
                }
            }

            a:hover {
                cursor: pointer;
            }

            &.facebookPost {
                background-image: url(../images/facebook-blu.svg);
                background-size: 31px 31px;
            }

            &.instagram {
                background-image: url(../images/instagram-blu.svg);
                background-size: 34px 34px;
            }

            &.tweet {
                background-image: url(../images/twitter-blu.svg);
                background-size: 35px 26px;
            }
        }

        @media screen and (min-width: @tabletWidth) {
            margin-top: 60px;

            article {
                margin-top: 15px;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            display: flex;
            margin-left: -7px;
            margin-right: -7px;

            article {
                margin-left: 7px;
                margin-right: 7px;
            }
        }
    }

    section.accordian {
        article {
            margin-bottom: 10px;

            h3 {
                background: @deepBlue url(../images/plus.svg) no-repeat right 15px center;
                color: @white;
                padding: 17px 90px 17px 25px;
                font-size: @fontSizeLarge;
                margin-bottom: 0;
                cursor: pointer;

                &.open {
                    background-image: url(../images/minus.svg);
                }
            }

            .text {
                background-color: @deepBlueVeryTransp;
                padding: 20px 25px 20px 25px;
                display: none;
            }

            &:first-of-type .text {
                display: block;
            }
        }
    }

    section.donationAmountForm {
        .highlight {
            border-top: none;
            margin-top: 0;
            margin-bottom: 20px;
            padding: 20px 15px;

            button[type=submit] {
                padding: 17px 40px;
            }

            .fundraisingPaymentLink {
                margin-top: 3rem;
                margin-bottom: 1.4rem;
                font-size: @fontSizeBigger;

                a {
                    color: @deepBlue;
                }
            }

            .amounts {
                margin-top: 1.8rem;
                margin-bottom: 1.2rem;
            }

            form {
                overflow: hidden;
                padding-bottom: 30px;

                #frm_DonationAmount {
                    opacity: 0;
                    position: absolute;
                    left: -1000px;
                }
            }

            @media screen and (min-width:@tabletWidth) {
                .amount {
                    display: flex;

                    ol.amounts {
                        flex: 1 1 50%;
                        margin-right: 10px;

                        li {
                            height: 59px;
                        }
                    }

                    .amountField {
                        flex: 1 1 40%;
                        margin-top: 1.8rem;
                    }
                }

                .fundraisingPaymentLink {
                    margin-top: 0.4rem;
                }
            }

            @media screen and (min-width:@tabletWidth) {
                .amount {

                    .amountField {
                        flex: 1 1 20%;
                    }
                }
            }

            @media screen and (min-width:@desktopWidth) {
                padding-left: 20px;

                .form {
                    width: 54%;
                }

                form {
                    padding-bottom: 10px;
                }
            }
        }

        @media screen and (min-width:@desktopWidth) {
            .page {
                position: relative;

                aside {
                    position: absolute;
                    top: -30px;
                    right: 11px;
                    z-index: 10;
                    width: 33%;
                }
            }
        }
    }

    section.whyDonateCTA {
        background-color: @lightBlue;
        color: @white;

        .inner {
            padding: 60px 20px 40px;
            position: relative;
            max-width: 823px;
            .centreUsingMargins();
        }

        img {
            position: relative;
            z-index: 10;
            margin-left: 15px;
            margin-bottom: 25px;
        }

        h2 {
            display: inline-block;

            span {
                display: inline-block;
            }

            span:first-of-type {
                font-size: @fontSizeHuge;
                font-weight: bold;
                background-color: @deepBlue;
                transform: rotate(-1.5deg);
                padding: 1px 14px 4px;
                position: absolute;
                left: 84px;
                top: 53px;
                z-index: 5;
            }

            span:nth-of-type(2) {
                font-size: @fontSizeEnormous;
                font-weight: 900;
                background-color: @red;
                transform: rotate(-5deg);
                padding: 6px 17px 8px;
                position: absolute;
                left: 156px;
                top: 86px;
            }
        }

        @media screen and (min-width: @tabletWidth) {
            .inner {
                display: flex;
            }

            .heading {
                flex: 1 1 60%;
                max-width: 350px;
            }

            p.subheading {
                flex: 1 1 40%;
                font-size: @fontSizeBig;
            }
        }

        @media screen and (min-width: @tabletWidth) {
            .inner {
                padding: 60px 20px 40px;

                .subheading {
                    margin-top: 1rem;
                }
            }
        }
    }

    section.didYouKnowCTA {
        margin-top: 30px;
        position: relative;

        .image {
            margin: 0;
            background: @deepBlue url() no-repeat center center;
            background-size: cover;
            height: 205px;
            position: relative;
            overflow: hidden;

            &:after { //angled image crop
                content: '';
                background-color: @deepBlue;
                display: block;
                width: 120%;
                height: 100px;
                position: absolute;
                bottom: -100px;
                left: 0;
                transform: rotate(-5deg);
                transform-origin: top left;
                z-index: 1;
            }
        }

        .text {
            background-color: @deepBlue;
            color: @white;
            padding: 27px 23px 20px;

            h2, h3 {
                font-weight: bold;
            }

            p {
                font-size: 1.1rem;
                font-weight: 100;
            }

            a {
                color: @white;
            }
        }

        @media screen and (min-width:@tabletWidth) {
            overflow: hidden;

            .image {
                height: 100%;
                width: 287px;
                position: absolute;

                &:after {
                    bottom: auto;
                    left: auto;
                    right: -100px;
                    top: 0;
                    transform: rotate(11deg);
                    transform-origin: top left;
                    width: 100px;
                    height: 120%;
                }
            }

            .text {
                float: right;
                margin-left: 287px;

                h2 {
                    font-size: @fontSizeMassive;
                }
            }
        }

        @media screen and (min-width:@desktopWidth) {
            .image {
                width: 360px;
            }

            .text {
                padding: 45px 45px 35px;
                margin-left: 360px;

                h2 {
                    margin-bottom: 1.8rem;
                }

                h2, p {
                    line-height: 1.6rem;
                }
            }
        }
    }

    section.yourDonationCould {
        &.highlight {
            padding-bottom: 50px;

            @media screen and (min-width:@tabletWidth) {
                .page > p {
                    font-size: @fontSizeBigger;
                    max-width: 719px;
                }

                .thumbLinks article span {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
        }
    }

    section.ctaStrip {
        color: @white;
        text-align: center;
        margin-top: 30px;
        padding: 50px 0 30px;
        background-size: cover;
        background-repeat: no-repeat;


        .inner {
            margin: 0 auto;
            max-width: 721px;
        }

        h2 {
            font-size: @fontSizeBigger;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            max-width: 536px;
            margin: 0 auto 1rem;
        }

        .btns {
            margin-top: 2rem;
        }

        @media screen and (min-width:@tabletWidth) {
            h2 {
                font-size: @fontSizeMassive;
            }

            a[role=button] {
                padding: 15px 40px;
            }
        }
    }

    section .supportUsText {
        margin-top: 40px;

        @media screen and (min-width:@tabletWidth) {

            p {
                font-size: @fontSizeBigger;
                max-width: 720px;
            }
        }
    }

    section#volunteer {
        padding-bottom: 3rem;

        > .page {
            > h3 {
                text-align: center;
                font-size: @fontSizeBig;
                margin: 1.5em 0 0.8em;
            }

            > p {
                max-width: 720px;
            }
        }

        @media screen and (min-width: @tabletWidth) {
            > .page > h3 {
                font-size: @fontSizeMassive;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            padding-bottom: 5rem;

            .thumbLinks article {
                flex-basis: 20%;
                min-width: 210px;
            }
        }
    }

    aside.pad a {
        color: @bodyTextColour;
    }

    aside.categories {
        max-width: none;

        h3 {
            margin-bottom: 1.6em;
            font-size: 1.3rem;
            font-weight: 900;
        }

        .inner {
            padding: 25px 20px;
            border-width: 2px;
        }

        ul {
            li {
                list-style-type: none;
                margin-left: 0;
                line-height: @fontSizeMassive;
                font-weight: bold;

                a {
                    text-decoration: none;
                }
            }
        }

        @media screen and (min-width: @tabletWidth) {
            ul {
                column-count: 2;
            }

            .inner {
                padding: 25px 40px;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            ul {
                column-count: 1;
            }

            .inner {
                padding: 25px 20px 10px;
            }
        }
    }

    fieldset.contactPreferences {
        div.radioHeader {
            max-width: 250px;
            display: flex;
            margin: 2rem 0 -1rem;

            span {
                display: block;
                flex: 1 1 10%;
                min-width: 70px;

                &:first-of-type {
                    flex-basis: 75%;
                }
            }
        }

        div.radios {
            max-width: 250px;

            > label {
                display: flex;
                align-items: flex-start;

                > span, > label {
                    display: block;
                    position: relative;
                    top: 7px;
                }

                > span {
                    flex: 1 1 75%;
                    font-weight: 900;
                }

                label {
                    display: block;
                    flex: 1 1 10%;
                    margin-top: 0;
                    top: 0;

                    span {
                        display: none;
                    }
                }
            }
        }
    }

    section.notices {
        margin-top: 2rem;
        margin-bottom: 3rem;

        article {
            margin-bottom: 20px;
            padding: 30px 20px 20px;
            background-color: @beige;

            h3 {
                margin-bottom: 1em;
                font-size: @fontSizeBigger;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            display: flex;

            article {
                flex: 1 1 45%;

                &:first-of-type {
                    margin-right: 14px;
                }
            }
        }
    }

    form section.privacyNotice {
        margin-top: 2rem;

        p {
            font-size: @fontSizeTiny;
        }
    }

    #frmVolunteerRole .privacyNotice {
        margin-top: 0 !important;
    }

    aside.phone {
        position: relative;
        margin-top: 60px;

        &:before {
            content: '';
            background: @white url(../images/phone-bk.svg) no-repeat 0 0;
            background-size: 100% 100%;
            display: block;
            width: 40px;
            height: 71px;
            position: absolute;
            top: -35px;
            left: 19px;
            z-index: 10;
        }

        .inner {
            padding-top: 55px;
        }

        @media screen and (min-width: @tabletWidth) {
            &:before {
                width: 48px;
                height: 85px;
            }

            .inner {
                padding-top: 60px;
            }
        }

        .text {
            font-weight: bold;
            font-size: @fontSizeBig;
            max-width: 394px;
            .centreUsingMargins();
        }

        .telephone {
            font-size: @fontSizeMassive;
            font-weight: 900;
            background-color: @telephoneHighlightColour;
            display: inline-block;
            padding: 7px 15px;

            a {
                color: @white;
            }
        }

        @media screen and (min-width: @desktopWidth) {
            margin-top: 0;
        }
    }

    section.localBranchSuggestedSearch {
        margin-bottom: 50px;
    }

    aside {
        .bigNumber {
            font-size: @fontSizeColossal;
            font-weight: 900;
            margin-bottom: .6rem;
        }
    }

    .filters {
        text-align: right;

        select {
            padding: 11px 60px 11px 20px;
            font-size: 1.15rem;
            background: @filterSelectBackgroundColour url(../images/arrow-down.svg) no-repeat right 15px center;
            color: @filterSelectTextColour;
            font-weight: bold;
            border: none;
            -webkit-appearance: none;
            outline: none;
        }
    }

    .withInfo {
        .text {
            margin-bottom: 2rem;
        }

        aside {
            border: @asideBorder;
            border-width: 2px;
            margin-bottom: 20px;
            padding: 25px 20px 15px;

            h3 {
                font-size: @fontSizeBigger;
                margin-bottom: 1.5rem;
            }

            .number {
                font-size: (@fontSizeColossal - 0.4);
                font-weight: 900;
                display: inline-block;
                margin-right: 0.3rem;
            }

            &.skills {
                li {
                    background: transparent url() no-repeat left center;
                    list-style-type: none;
                    margin-left: 0;
                    padding: 5px 0 5px 60px;
                    font-size: @fontSizeLarge;
                }
            }
        }

        @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
            .asides {
                display: flex;
                margin-left: -7px;
                margin-right: -7px;
            }

            aside {
                flex: 1 1 34%;
                margin-left: 7px;
                margin-right: 7px;

                .number {
                    margin-top: 1rem;
                }
            }
        }

        @media screen and (min-width:@desktopWidth) {
            > article {
                display: flex;

                .text {
                    flex: 1 1 65%;
                }

                .asides {
                    flex: 1 1 30%;
                    min-width: 352px;
                    margin-left: 30px;
                }
            }

            .text {
                margin-bottom: 0;
            }
        }
    }

    div.address {
        margin-top: 2rem;

        .title {
            font-weight: bold;
        }
    }

    .desktopFlex.raggedBottom form {
        fieldset + fieldset > .dynalabel:first-child {
            margin-top: -2rem;
        }

        .with-margin-top {
            margin-top: 1rem !important;
        }
    }

    .playht-audio {
        width: 100%;

        + .caption {
            font-style: italic;
            font-size: 0.94rem;
            margin-left: 1.1rem;
        }
    }

    .RTE {

        blockquote {
            margin-top: 45px;
            margin-bottom: 1.5rem;
            background-color: @faint;
            padding: 35px 25px 25px;
            position: relative;
            font-size: 1.1rem;
            font-weight: bold;
            display: block;

            &:before {
                display: block;
                position: absolute;
                content: '';
                width: 57px;
                height: 50px;
                top: -22px;
                left: 11px;
                background: transparent url(../images/quote-bubble.svg) no-repeat 0 0;
                background-size: 100% 100%;
            }

            @media screen and (min-width: @tabletWidth) {
                margin-left: 50px;
                padding-left: 40px;
                padding-top: 25px;

                &:before {
                    width: 72px;
                    height: 63px;
                    left: -50px;
                    top: -19px;
                }
            }
        }

        ul {
            margin-top: 1rem;

            li {
                margin-bottom: 1.3rem;
            }
        }
    }
    /*Template-specific styling*/
    main {
        .left {
            float: left;
        }

        .right {
            float: right;
        }
        //&.Home, &.SectionPage, &.ContentPage, &.NewsArticle {
        .greybg {
            margin-top: 4rem;
            padding-bottom: 3rem;
        }

        section.latestNews, section.newsletterSignUp {
            @media screen and (max-width: (@desktopWidth - 1)) {
                max-width: 535px;
                .centreUsingMargins();
            }
        }

        section.newsletterSignUp {
            @media screen and (min-width: @desktopWidth) {
                margin-right: 40px;

                form input.inline {
                    width: 100%;
                }

                form div.inline {
                    width: 44%;

                    &.last {
                        float: right;
                    }
                }
            }
        }
        /*            section.howYouCanHelp, section.caseStudies, section.lastSection {
                margin-bottom: 4rem;
            }*/

        .upshifted {
            section.newsletterSignUp {
                @media screen and (min-width: @tabletWidth) {
                    article {
                        margin-top: -75px;
                    }
                }

                @media screen and (min-width: @desktopWidth) {
                    article {
                        margin-top: -55px;
                    }
                }
            }
        }
        //}

        &.ContentPage {
            .lastSection {
                margin-top: 60px;
            }
        }

        &.ServiceIndex {
            @media screen and (max-width: (@desktopWidth - 1)) {

                section.thumbLinks {
                    display: block;
                }
            }

            .lastSection {
                margin-top: 60px;
                margin-bottom: 10px;
            }
        }

        &.ForceslineDetail {

            section.contact {
                .bodyText p {
                    font-size: @fontSizeLarge;

                    @media screen and (min-width: @tabletWidth) {
                        font-size: (@fontSizeBig + 0.1);
                    }
                }
            }

            @media screen and (min-width: @desktopWidth) {
                aside.phone {
                    margin-top: -30px;
                }
            }
        }

        &.VolunteerRole {
            .articleWithSidebar {
                article {
                    margin-bottom: 0;
                }

                aside {
                    div.inner {
                        margin-bottom: 10px;
                    }

                    @media screen and (min-width: @tabletWidth) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.campaignLandingPage {
            .carouselNc {
                clear: both;
                padding-bottom: 10px;
                padding-top: 10px;
            }

            .articleWithSidebar {
                article {
                    .introText {
                        font-size: 21px;
                        line-height: 27px;
                    }
                    // p {
                    //     font-size: 21px;
                    //     line-height: 27px;
                    // }
                    a:not([role=button]) {
                        color: #007eb6;
                    }
                }
            }


            .content-txt {
                .introText {
                    font-size: 21px;
                    line-height: 27px;
                }

                a:not([role=button]) {
                    color: #007eb6;
                }
            }


            .volunteerSearchForm {
                margin-top: 30px;

                form {
                    @media screen and (max-width:(@desktopWidth - 1)) {
                        display: flex;
                        flex-direction: column;
                    }

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        position: relative;
                        margin-bottom: 100px;
                        padding: 0 30px 0;
                    }
                }

                .search-form {
                    flex: 1 1 50%;
                    background-color: #fff;
                    padding: 30px;
                    box-shadow: 0px 0px 6px #00000029;

                    @media screen and (max-width:(@desktopWidth - 1)) {
                        flex: 1 1 100%;
                        order: 1;
                        margin-top: 20px;
                    }

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        margin-top: -10px;
                        position: relative;
                        bottom: -35px;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @media screen and (max-width: 414px) {
                        flex: 1 1 100%;
                        order: 1;
                        margin-top: 20px;
                    }



                    h3 {
                        font-size: 18px;
                        line-height: 16px;
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }

                    .btns {
                        display: inline-block;
                        margin-left: 60px;
                        top: -20px;
                        position: relative;
                        margin-bottom: -20px;

                        @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                            width: 50%;
                            margin-left: auto;


                            button {
                                float: right;
                            }
                        }

                        @media screen and (max-width: 414px) {
                            margin-left: 0;
                            margin-top: 60px;
                        }
                    }

                    .dynalabel {
                        display: inline-block;
                        margin-top: 0;
                        top: -20px;
                        margin-bottom: -20px;

                        @media screen and (max-width: 414px) {
                            top: 10px
                        }

                        input::placeholder {
                            font-style: italic;
                        }
                    }
                }

                p {
                    a {
                        color: #007EB6;
                    }
                }

                .text {

                    @media screen and (max-width:(@desktopWidth - 1)) {
                        flex: 1 1 100%;
                        order: 0;
                    }


                    @media screen and (max-width: 414px) {
                        flex: 1 1 100%;
                        order: 0;

                        h3 {
                            margin-top: 0;
                        }
                    }
                }
            }


            .infographic-banner {
                h4 {
                    font-weight: normal;
                }

                + .together {
                    margin-top: 30px;
                }

                + .subPages {
                    margin-top: 20px;
                }
            }

            .together {
                + .infographic-banner {
                    margin-top: 20px;
                }
            }

            .contentWithSidebarNc {
                + .infographic-banner {
                    margin-top: 0;
                }
            }

            .onwardJourneyLinksNc {
                + .infographic-banner {
                    margin-top: 5px;
                }
            }

            .downloads {
                + .onwardLinksBlueBorderNc {
                    margin-top: 40px;
                }
            }

            > section {
                section {
                    &:last-child {
                        &.onwardLinksBlueBorderNc {
                            margin-bottom: 30px;
                        }
                    }
                }
            }

            .news.carousel {
                max-width: 686px;
            }
        }

        &.LocalBranch {
            .contact {

                @media screen and (min-width: 601px) and (max-width: 953px) {
                    padding-bottom: 50px;
                }

                #frmContactMessage {
                    max-width: 100%;
                    margin-top: 10px;
                    display: block;

                    @media screen and (max-width: @tabletWidth) {
                        margin-bottom: 30px;
                    }

                    .new-flex {
                        display: flex;

                        @media screen and (max-width: @tabletWidth) {
                            flex-direction: column;
                        }

                        .col-flex {
                            flex: 1 1 50%;

                            div.in-moddle {
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);
                                margin: 0 30px;
                                font-weight: bold;

                                @media screen and (max-width: @tabletWidth) {
                                    top: 0;
                                    transform: none;
                                }
                            }

                            div.checkbox {
                                font-weight: bold;
                            }
                        }
                    }

                    .btn-right {
                        text-align: right;

                        @media screen and (max-width: 414px) {
                            text-align: left;
                        }
                    }

                    .privacyNotice {
                        p {
                            font-size: 16px;
                            line-height: 21px;

                            @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                                padding-left: 20px;
                            }
                        }
                    }
                    // div,
                    // h3 {
                    //     display: block;
                    // }
                }
            }

            .caseStudies {
                p {
                    max-width: 100%;
                }
            }

            .onward-journey-links {
                margin-top: 30px;
                margin-bottom: 10px;

                article {
                    > a {
                        padding: 50px 50px 100px;

                        @media screen and (max-width:(@desktopWidth - 1)) {
                            padding: 20px;
                        }
                    }

                    span.more {
                        bottom: 50px;
                    }
                }

                h3 {
                    display: block;
                }

                .text {
                    margin-bottom: 15px;
                }
            }

            .scroll-nav {
                display: block;
                background-color: #fff;
                position: relative;

                .arrows {
                    display: none;
                    position: absolute;
                    z-index: 10;
                    top: 19px;
                    width: 100%;

                    .forward {
                        position: absolute;
                        right: 10px;
                        display: block;
                        top: 0;
                    }

                    .back {
                        left: 10px;
                        position: absolute;
                    }
                }

                @media screen and (max-width: @tabletWidth) {
                    overflow: hidden;

                    .padded {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .arrows {
                        display: block;
                    }
                }

                @media screen and (max-width: @smallPhoneWidth) {
                    .padded {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                ul {
                    background-color: #EDEDED80;
                    display: flex;
                    padding: 0;
                    margin: 0;

                    @media screen and (max-width: @tabletWidth) {
                        overflow: scroll;
                    }

                    li {
                        flex: 1 1 25%;
                        text-align: center;
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        @media screen and (max-width: @tabletWidth) {
                            min-width: 220px;
                        }


                        a {
                            text-decoration: none;
                            color: #002664;
                            font-size: 18px;
                            line-height: 18px;
                            font-weight: bold;
                            padding: 20px 25px;
                            display: block;
                            border: 2px solid #EDEDED;
                            border-right: 1px solid #EDEDED;
                            border-left: 1px solid #EDEDED;

                            &:hover {
                                border-top: 5px solid #127EB6;
                                background-color: #fff;
                                padding-top: 17px;
                            }
                        }

                        &.active {
                            a {
                                border-top: 5px solid #127EB6;
                                background-color: #fff;
                                padding-top: 17px;
                            }
                        }

                        &:first-child {
                            a {
                                border-left: 2px solid #EDEDED;
                            }
                        }

                        &:last-child {
                            a {
                                border-right: 2px solid #EDEDED;
                            }
                        }
                    }
                }
            }

            .hero {
                .outer {
                    margin-bottom: 0;
                }
            }

            #contactUs {
                .desktopFlex {
                    position: relative;
                    margin-bottom: 40px;

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        width: 90%;
                    }


                    @media screen and (max-width: 812px) {
                        display: table;
                        margin-bottom: 60px;
                    }
                }

                .col-flex {
                    flex: 1 1 50%;

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        width: 100%;
                        flex: 1 1 100%;
                    }
                }

                .social-share {
                    margin: 0;
                    padding: 0;
                    padding-left: 40px;

                    li {
                        list-style: none;
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        margin-right: 10px;

                        a {
                            display: block;
                            width: 27px;
                            height: 27px;
                            position: relative;

                            img {
                                width: 100%;
                                position: absolute;
                                max-width: 27px;
                                height: auto;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }

                .phone-info {

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        width: 100%;
                        position: relative;
                    }

                    @media screen and (min-width: 813px) and (max-width: 953px) {
                        position: absolute;
                        top: 0;
                        right: 15px;
                    }

                    .blue-box {
                        display: block;
                        border: 3px solid #007EB6;
                        max-width: 310px;
                        background-color: #fff;
                        min-height: 100px;
                        position: relative;
                        top: -85px;
                        right: 0;
                        float: right;
                        padding: 20px;
                        padding-top: 50px;

                        @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                            float: none;
                            margin-left: auto;
                            margin-right: auto;
                            max-width: 460px;
                            padding: 40px;
                        }

                        @media screen and (max-width: 812px) {
                            top: 30px;
                        }

                        h4 {
                            font-size: 19px;
                            line-height: 22px;
                        }

                        p {
                            text-align: center;
                        }

                        .btn-phone {
                            display: table;
                            background-color: #007EB6;
                            font-size: 28px;
                            line-height: 28px;
                            color: #fff;
                            font-weight: bold;
                            padding: 10px 15px;
                            text-align: center;
                            margin: 20px auto 20px;
                        }

                        .btn-chat {
                            display: table;
                            background-color: #022664;
                            font-size: 16px;
                            line-height: 28px;
                            color: #fff;
                            font-weight: bold;
                            padding: 10px 20px;
                            text-align: center;
                            margin: 20px auto 20px;
                            padding-top: 3px;
                            cursor: pointer;
                            text-decoration: none;

                            img {
                                display: inline-block;
                                width: 28px;
                                top: 10px;
                                position: relative;
                                margin-right: 5px;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            left: 20px;
                            top: -25px;
                            width: 70px;
                            height: 64px;
                            display: block;
                            background: #fff url(../images/noun_chat.svg) no-repeat;
                        }
                    }
                }
            }
        }

        &.VolunteerApplicationThankYou {
            .btns {
                margin-bottom: 4rem;
            }

            article {
                h2 {
                    font-size: @fontSizeMassive;
                }

                p {
                    &.roleName, &.roleLocation {
                        padding: 5px 0 5px 40px;
                        background: transparent url() no-repeat left center;
                        font-size: @fontSizeBig;
                        margin-bottom: 0.25em;
                    }

                    &.roleName {
                        background-image: url(../images/role-person.svg);
                    }

                    &.roleLocation {
                        background-image: url(../images/location-arrow.svg);
                    }
                }

                .RTE {
                    margin-top: 2.5rem;
                }
            }
        }

        &.VolunteeringIndex, &.Event {
            section aside {
                margin-bottom: 20px;
            }

            .imageOrVideo {
                position: relative;
                top: -40px;
            }

            .volunteerSearchForm {

                form {
                    @media screen and (max-width:(@desktopWidth - 1)) {
                        display: flex;
                        flex-direction: column;
                    }

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        position: relative;
                        margin-bottom: 100px;
                        padding: 0 30px 0;
                    }
                }

                .search-form {
                    flex: 1 1 50%;
                    background-color: #fff;
                    padding: 30px;
                    box-shadow: 0px 0px 6px #00000029;

                    @media screen and (max-width:(@desktopWidth - 1)) {
                        flex: 1 1 100%;
                        order: 1;
                        margin-top: 20px;
                    }

                    @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                        margin-top: -10px;
                        position: relative;
                        bottom: -35px;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @media screen and (max-width: 414px) {
                        flex: 1 1 100%;
                        order: 1;
                        margin-top: 20px;
                    }



                    h3 {
                        font-size: 18px;
                        line-height: 16px;
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }

                    .btns {
                        display: inline-block;
                        margin-left: 60px;
                        top: -20px;
                        position: relative;
                        margin-bottom: -20px;

                        @media screen and (min-width: 415px) and (max-width:(@desktopWidth - 1)) {
                            width: 50%;
                            margin-left: auto;


                            button {
                                float: right;
                            }
                        }

                        @media screen and (max-width: 414px) {
                            margin-left: 0;
                            margin-top: 60px;
                        }
                    }

                    .dynalabel {
                        display: inline-block;
                        margin-top: 0;
                        top: -20px;
                        margin-bottom: -20px;

                        @media screen and (max-width: 414px) {
                            top: 10px
                        }

                        input::placeholder {
                            font-style: italic;
                        }
                    }
                }

                p {
                    a {
                        color: #007EB6;
                    }
                }

                .text {

                    @media screen and (max-width:(@desktopWidth - 1)) {
                        flex: 1 1 100%;
                        order: 0;
                    }


                    @media screen and (max-width: 414px) {
                        flex: 1 1 100%;
                        order: 0;

                        h3 {
                            margin-top: 0;
                        }
                    }
                }
            }

            .infographic-banner {
                h4 {
                    font-weight: normal;
                }
            }

            .titled {
                h2 {
                    max-width: 100%;
                }
            }

            .faq-section {
                margin-bottom: 50px;


                a[role=button] {
                    border-radius: 0;
                    background-color: #002664;
                    display: block;
                    position: relative;

                    &:hover,
                    &:focus {
                        transform: none;
                        color: #ffffff;
                    }

                    &:after {
                        content: '';
                        display: block;
                        width: 26px;
                        height: 26px;
                        background-image: url('/images/add.png');
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        top: 12px;
                        right: 30px;
                    }
                }

                a[aria-expanded=true] {
                    &:after {
                        background-image: url('/images/minus.png');
                    }
                }

                .panel-collapse {
                    background-color: rgba(#002664, 0.2);
                    top: -12px;
                    position: relative;

                    .panel-body {
                        padding: 30px;
                    }
                }
            }
        }

        &.Event {
            section .thumbLinks article .text {
                max-width: unset;
            }

            a.section-link, a.section-link:hover, a.section-link:active {
                text-decoration: none;
                color: unset;
            }

            .readyForSignupCTA {
                background-color: #EDEDED;
                padding: 1.5rem;
                margin: 20px 0 20px 0;

                p {
                    margin-bottom: 30px;
                }

                .button {
                    background-color: #E00034;
                    display: inline-block;

                    &:hover {
                        color: #ffffff;
                    }
                }

                h3 {
                    text-align: left;
                    font: normal normal 900 28px/31px Gotham;
                    letter-spacing: 0px;
                    color: #0098DB;
                }
            }

            #map {
                height: 600px; /* The height is 400 pixels */
                width: 100%; /* The width is the width of the web page */
                margin: 1rem 0 1.2rem 0;
            }

            .thumbLinks {
                display: block;
            }

            .simpleLinks {
                display: block;
            }

            .infoBlocks {
                margin-top: 2rem;
            }

            @media screen and (min-width:@tabletWidth) {
                .infoBlocks {
                    margin-top: 3rem;
                }
            }

            @media screen and (min-width:@desktopWidth) {
                .about .text {
                    max-width: 720px;
                    .centreUsingMargins();
                }

                .infoBlocks {
                    margin-left: 0;
                    margin-right: 0;
                }

                .thumbLinks {
                    display: flex;
                    margin-top: 3rem;

                    article {
                        flex: 1 1 45%;

                        h3 {
                            font-size: @fontSizeMassive;
                            margin-bottom: 3rem;
                        }

                        a:first-of-type {
                            padding: 35px 35px 95px;
                        }

                        span.more {
                            bottom: 35px;
                        }
                    }
                }

                .simpleLinks {
                    display: flex;
                    margin-top: 4rem;
                    margin-bottom: 4rem;

                    article {
                        flex: 1 1 30%;

                        a:first-of-type {
                            padding-top: 30px;
                        }
                    }
                }
            }
        }

        &.EventIndex, &.EventSignupThankYou {
            section > section.titled {
                h2 {
                    max-width: 100% !important;
                }
            }

            #eventSearchForm {
                @media screen and (min-width:@desktopWidth) {
                    padding-bottom: 30px;
                }
            }

            #resultsMessage {
                font-weight: 900;
                //margin-top: 2rem;

                .label {
                    font-size: 1.1rem;
                }
            }

            #eventResults .results {
                position: relative;
                margin-bottom: 3rem;

                .articles {
                    width: 100%;

                    @media screen and (min-width:@desktopWidth) {
                        article {
                            max-width: 29.9%;
                        }
                    }
                }

                &.list-view {
                    article {
                        padding: unset;
                        padding-bottom: 30px;
                        margin-bottom: 2.5rem;
                    }

                    .imageThumbnail {
                        display: none;
                        padding: unset;
                    }

                    @media screen and (min-width:@desktopWidth) {
                        .imageThumbnail {
                            display: unset;
                        }

                        .articles {
                            display: unset;
                        }

                        article {
                            max-width: 100%;
                            width: 100%;
                            height: 150px;
                            display: table;
                            overflow: hidden;
                            padding: unset;
                            margin-bottom: 2.5rem;

                            h3 {
                                font-size: @fontSizeHuge;

                                > .lastWord {
                                    display: inline-block;
                                    background: transparent url(../images/arrow-right-black.png) no-repeat right center;
                                    background-size: 18px auto;
                                    padding-right: 28px;
                                    height: auto;
                                }
                            }

                            a {
                                padding: unset;
                                cursor: pointer;
                            }

                            .imageThumbnail {
                                width: 200px;
                                height: 150px;
                                position: unset;
                                float: left;
                            }

                            div.listview-content {
                                margin-left: 210px;
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);

                                div {
                                    display: inline-flex;
                                }
                            }

                            div.btns {
                                display: none;
                            }
                        }
                    }
                }

                &.noresults {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    font-weight: 900;
                }
            }

            #map {
                height: 600px; /* The height is 400 pixels */
                width: 100%; /* The width is the width of the web page */
            }

            #featuredEvent {
                border-top: none;
                margin-top: 150px;
                padding-bottom: 4rem;

                .imageOrVideo {
                    margin-bottom: 1.5rem;

                    img {
                        width: 100%;
                        height: auto;
                        margin-top: -90px;
                    }

                    a {
                        margin-top: -90px;
                        display: inline-block;

                        img {
                            margin-top: 0;
                        }
                    }
                }


                .btns {
                    margin-top: 2rem;
                }

                @media screen and (min-width:@desktopWidth) {
                    padding-bottom: 3rem;

                    article {
                        display: flex;
                        flex-direction: row-reverse;
                    }

                    .imageOrVideo {
                        flex: 1 1 55%;

                        img {
                            margin-top: -35px;
                        }

                        a {
                            margin-top: -35px;

                            img {
                                margin-top: 0;
                            }
                        }
                    }

                    .text {
                        flex: 1 1 40%;
                        margin-right: 5%;
                        margin-top: 40px;
                    }
                }
            }

            .thumbLinks {
                display: flex;
                margin-top: 3rem;

                article {
                    flex: 1 1 45%;

                    .text {
                        max-width: unset;
                    }

                    h3 {
                        font-size: @fontSizeMassive;
                        margin-bottom: 3rem;
                    }

                    a:first-of-type {
                        padding: 35px 35px 95px;
                    }

                    span.more {
                        bottom: 35px;
                    }
                }
            }

            #results {
                h5 {
                    margin-bottom: 1.2rem;
                    font-size: 1.2rem;
                }

                h6 {
                    margin-bottom: 0.9rem;
                    font-size: 0.9rem;
                }

                .date, .location, .type {
                    font-weight: normal;
                    display: block;
                    //margin-bottom: 0;
                }

                .location {
                    background-position-x: 5px;
                    padding-left: 41px;
                }

                article {
                    padding-bottom: 30px
                }
            }

            .volunteerResults .articles {
                margin-top: 0;
            }

            .filters {
                //margin-top: 30px;
            }

            .pagination {
                margin-top: 3rem;
            }

            @media screen and (min-width:@tabletWidth) {

                #eventSearchForm {
                    .btns {
                        margin: 2rem 0 0;
                        display: block;
                    }

                    .btns.refresh {
                        margin: 2rem 0 0;
                        display: inline-block;
                    }
                }
            }

            @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {

                section.subPages {
                    .articles {
                        display: flex;
                        margin-top: 0;
                        flex-flow: row wrap;
                    }

                    article {
                        padding-bottom: 30px;
                        max-width: 45%;
                        flex-basis: 40%;
                    }
                }

                #eventResults {
                    article {
                        max-width: 585px;
                        padding-top: 0;
                        padding-right: 292px;

                        a {
                            padding-top: 25px;
                            padding-left: 30px;
                        }

                        .imageThumbnail {
                            width: 292px;
                            height: 100%;
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }

            @media screen and (min-width:@desktopWidth) {
                section.subPages {
                    article {
                        padding-bottom: 30px;
                        max-width: 31.9%;
                        flex-basis: 25%;
                    }
                }

                #eventResults {
                    .articles {
                        margin: 1rem 30px 0;
                    }

                    .resultsSliders {
                        margin-left: -10px;

                        .forward {
                            margin-right: -20px
                        }
                    }
                }
            }
        }

        &.EventSignupThankYou {
            section.titled {
                padding-bottom: 0;
            }

            @media screen and (min-width:@tabletWidth) and (max-width:(@desktopWidth - 1)) {
                .asides {
                    display: flex;
                }

                aside {
                    flex: 1 1 40%;
                    margin: 0;

                    &:first-of-type {
                        margin-right: 14px;
                    }

                    .inner {
                        height: 100%;
                        padding: 20px 20px 15px;
                    }
                }
            }

            @media screen and (min-width:@desktopWidth) {
                .asides {
                    margin: -30px 0 0;

                    aside {
                        margin-bottom: 14px;
                    }
                }

                #featuredEvent {
                    margin-top: 20px;
                }
            }
        }

        &.ServicePage {
            .searchForm {
                margin-top: 4rem;
                margin-bottom: 3rem;

                form {
                    margin-right: 0;
                }
            }

            .searchResults {
                article {
                    margin-right: 0;
                }

                margin-bottom: 2rem;
            }
        }

        &.DonationLandingPage {
            .breadcrumb + section.titled {
                padding-bottom: 0;
            }

            aside {
                margin: 0 15px 50px;
            }

            @media screen and (min-width:@tabletWidth) and (max-width: (@desktopWidth - 1)) {
                .highlight {
                    padding-bottom: 50px;
                }

                .donationAmountForm {
                    .pageLayout();
                    .pad();
                }

                aside {
                    margin: -70px auto 50px auto;
                }
            }
        }

        &.SupportUs {
            section#howWeHelp {
                margin-top: 30px;
            }

            #subPages {
                margin-bottom: 15px;
            }

            @media screen and (min-width:@desktopWidth) {
                section.simpleLinks {
                    article {
                        min-width: 200px;
                    }
                }

                section.thumbLinks {
                    article {
                        flex: 1 1 20%;
                        min-width: 200px;
                    }
                }
            }
        }

        &.SiteSearchResults {
            section.titled {
                margin-top: 2rem;
            }
        }

        &.BranchSearchResults {

            #frmBranchFinder {

                button.geoSearchButton.noArrow {
                    background-image: none;
                }
            }

            @media screen and (min-width:@desktopWidth) {
                section > section aside {
                    margin-bottom: -45px;
                }
            }
        }

        &.VolunteerResults {

            section#search {
                margin-top: 1.8rem;
            }

            .btns, .dynalabel {
                display: inline-block;
            }

            .btns {
                margin-left: 20px;
            }

            #frmVolunteerSearch.results {
                margin-bottom: 2rem;

                h3 {
                    margin-top: 4rem;
                    margin-bottom: 0;
                }

                @media screen and (max-width:(@tabletWidth - 1)) {
                    .btns {
                        margin-left: 0;
                        display: block;
                    }
                }
            }
        }

        &.SupportUs {
            section.simpleLinks {
                display: block;
            }
        }

        &.EventSignupForm {
            section section.titled div.page {
                position: relative;
            }

            section.asides {
                margin-left: -15px;
                margin-right: -15px;

                aside {
                    @media screen and (max-width:(@tabletWidth - 1)) {
                        max-width: none;
                        border-top: 2px solid @lightBlue;
                        border-bottom: 2px solid @lightBlue;
                        padding: 15px 15px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-end;

                        p, h3 {
                            flex: 1 1 70%;
                            max-width: 70%;
                        }

                        h3 {
                            order: 2;
                        }

                        p {
                            order: 3;
                            margin-top: -3.1rem;
                        }

                        p.bigNumber {
                            flex: 1 1 25%;
                            order: 1;
                            margin-top: 0;
                            min-width: 100px;
                            padding: 20px 20px 0 0;
                            text-align: center;
                            box-sizing: border-box;
                            font-size: @fontSizeGigantic;
                            white-space: nowrap;

                            &.smaller {
                                font-size: @fontSizeMassive;
                            }
                        }

                        &:last-of-type {
                            border-top: none;
                        }
                    }

                    @media screen and (max-width:(@smallPhoneWidth - 1)) {
                        p, h3 {
                            flex: 1 1 40%;
                            max-width: 60%;
                        }

                        p.bigNumber {
                            flex: 1 1 14% !important;
                        }
                    }
                }

                @media screen and (min-width:(@tabletWidth)) {
                    display: flex;
                    .pad();

                    aside {
                        flex-basis: 50%;
                        border: 2px solid @lightBlue;
                        padding: 20px 20px 10px;
                        margin-bottom: 1.8rem;

                        &#registrationFee {
                            margin-right: 14px;
                        }
                    }
                }

                @media screen and (min-width:(@desktopWidth)) {
                    display: block;
                    float: right;
                    padding-left: 0;
                    padding-right: 0;
                    margin: -90px 0 0;
                    width: 31%;

                    aside {
                        border: 2px solid @lightBlue;
                        padding: 20px 20px 10px;
                        margin-bottom: 1.8rem;
                        max-width: 337px;
                        box-sizing: border-box;
                        background-color: @white;

                        &#registrationFee {
                            margin-right: 0;
                        }
                    }
                }
            }

            form {
                legend {
                    font-size: @fontSizeMassive;
                    font-weight: bold;
                }

                #pnlAddress, #paymentDetails {
                    legend {
                        margin-bottom: 1.5rem;
                    }
                }

                #paymentDetails {
                    > p:first-of-type {
                        font-weight: 900;
                        font-size: @fontSizeBig;
                        margin-bottom: 1rem;
                    }
                }

                .acceptedCards {
                    margin-top: 1.4rem;
                    margin-bottom: 3rem;

                    > p:first-of-type {
                        font-size: @fontSizeTiny;
                    }
                }
                /*input[type=text], input[type=tel] {
                    width: 215px;
                }*/
                .requiredMessage {
                    margin-top: 1.5rem;
                    margin-bottom: 1.2rem;
                }

                #VestSize {
                    label:first-of-type {
                        font-weight: 900;
                        display: block;
                        margin-bottom: 1rem;
                        font-size: @fontSizeBig;

                        .info {
                            float: right;
                            font-weight: bold;
                            font-size: @fontSizeNormal;

                            a {
                                text-decoration: none;
                                padding-left: 26px;
                                background: transparent url(../images/ico-info.svg) no-repeat left center;
                                background-size: 19px 19px;
                                min-height: 19px;
                                display: inline-block;
                            }
                        }
                    }

                    label:not(:first-of-type) {
                        font-size: @fontSizeBig;
                        font-weight: bold;
                        background-color: @beige;
                        padding: 10px 15px 10px 45px;
                        width: 40%;
                        display: inline-block;
                        margin: 0 10px 15px 0;
                        box-sizing: border-box;

                        &::before {
                            top: 10px;
                            left: 15px;
                            width: 18px;
                            height: 18px;
                        }

                        &::after {
                            top: 15px;
                            left: 20px;
                            border-width: 6px;
                        }
                    }
                }

                #DistanceOptions {
                    label:first-of-type {
                        font-weight: 900;
                        display: block;
                        margin-bottom: 1rem;
                        font-size: @fontSizeBig;
                    }

                    select, select > option {
                        font-size: @fontSizeBig;
                        font-weight: bold;
                    }
                }

                .btns {
                    text-align: center;
                }
            }

            @media screen and (min-width:(@tabletWidth)) {
                form {
                    #VestSize {
                        .info {
                            margin-right: 50px;
                        }

                        label:not(:first-of-type) {
                            width: 20%;
                        }
                    }

                    .btns {
                        text-align: left;
                    }

                    #ccDetails {
                        > .row {
                            > div:first-of-type {
                                //Credit card logos
                                float: right;
                                width: 30%;
                            }

                            > div:last-of-type {
                                //card fields
                                float: left;
                                width: 60%;
                            }
                        }
                    }
                }
            }

            @media screen and (min-width:(@desktopWidth)) {
                form {
                    width: 65%;
                }
            }
        }

        &.NewsArticle {
            figure.news-figure {
                width: fit-content;
                border: 3px solid #007EB6;

                &.left {
                    margin-right: 15px
                }

                &.right {
                    margin-left: 15px
                }

                &.center {
                    margin: 0 auto;
                    margin-bottom: 20px;
                }

                img.desktopImage {
                    width: 348px;
                    display: initial;
                }

                img.mobImage {
                    width: 100%;
                    display: none;
                }
            }

            @media screen and (max-width:(@tabletWidth)) {
                figure.news-figure {
                    width: fit-content;
                    border: 3px solid #007EB6;
                }
            }

            @media screen and (max-width:(@smallPhoneWidth)) {
                figure.news-figure {
                    width: 100%;
                    border: 3px solid #007EB6;

                    img.desktopImage {
                        display: none;
                    }

                    img.mobImage {
                        width: 100%;
                        display: initial;
                    }
                }
            }

            .news.carousel {
                border: 3px solid #007EB6;
                width: 100%;
                //height: 530px;

                .img-holder {
                    background-color: #70777e;
                    height: 376px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                img {
                    height: auto !important;
                    max-height: 376px;
                }

                .caption {
                    margin-top: 10px;
                    margin-left: 10px;
                    color: #000000;

                    h3 {
                        font-weight: bold;
                        font-size: 18px;
                    }

                    p {
                        text-align: left;
                        font: 16px;
                        letter-spacing: 0px;
                    }
                }

                .carousel-pagination {
                    font-weight: bold;
                    position: absolute;
                    top: 387px;
                    right: 20px;
                    z-index: 15;
                    padding-left: 0;
                    margin-left: -30%;
                    text-align: center;

                    .pagination {
                        text-align: center;
                        font: 18px;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        margin: unset;
                    }

                    .nav-left {
                        display: inline-block;
                        width: 10px;
                        height: 12px;
                        font-size: 30px;
                        opacity: 1;
                        background: transparent url(../images/pagination-previous-blue.svg) 0% 0% no-repeat padding-box;
                    }

                    .nav-right {
                        display: inline-block;
                        width: 10px;
                        height: 12px;
                        font-size: 30px;
                        opacity: 1;
                        background: transparent url(../images/pagination-next-blue.svg) 0% 0% no-repeat padding-box;
                    }
                }

                @media (max-width:(@mediumTabletWidth)) {
                    width: 100%;
                    overflow: hidden;

                    .img-holder {
                        background-color: #70777e;
                        height: 299px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    img {
                        height: auto !important;
                        max-height: 299px;
                    }

                    .carousel-pagination {
                        position: absolute;
                        top: 310px;
                        right: 20px;
                        z-index: 15;
                        padding-left: 0;
                        margin-left: -30%;
                    }
                }

                @media (max-width:(@tabletWidth)) {
                    width: 100%;

                    .img-holder {
                        background-color: #70777e;
                        height: 299px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    img {
                        height: auto !important;
                        max-height: 299px;
                    }

                    .carousel-pagination {
                        position: absolute;
                        bottom: 10px;
                        top: unset;
                        z-index: 15;
                        padding-left: 0;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        section.whatsNearYou {
            height: 328px;
            align-items: center;

            .geoSearchButton {
                background-color: #007EB6;
            }

            .articles article > span {
                padding-bottom: 0;
                padding-top: 0;
            }

            input, select {
                border-bottom: 3px solid #002664;
            }

            .borderdBox {
                border: solid 2px #007eb6;
                background-color: white;
                display: flex;
                height: 180px;
                align-items: center;
                width: 100%;
                justify-content: center;

                .form {
                    display: flex;
                    padding: 10px;

                    h4 {
                        margin-top: 18px;
                        margin-right: 25px;
                    }

                    form {

                        .form-control-div, .btns {
                            margin-top: 15px;
                        }

                        div.inline {
                            margin-right: 20px !important;
                        }

                        div.form-control-div {
                            text-align: center;

                            select {
                                background: transparent;
                                background-image: url("../images/drop-arrow.png");
                                background-repeat: no-repeat;
                                background-position-x: 100%;
                                background-position-y: 15px;
                            }

                            label {
                                margin-right: 10px;
                            }

                            input.geoPostcode {
                                width: 220px;
                            }

                            select.searchType {
                                width: 220px;
                            }

                            label.geoPostcode {
                                vertical-align: middle;
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: transparent url('../images/location_on_black_24dp.svg') 0% 0% no-repeat padding-box;
                                opacity: 1;
                            }

                            label.searchType {
                                vertical-align: middle;
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: transparent url('../images/tune_black_24dp.svg') 0% 0% no-repeat padding-box;
                                opacity: 1;
                            }
                        }
                    }
                }
            }


            @media screen and (max-width:(@mediumTabletWidth)) {
                width: 100%;
                height: auto;

                .borderdBox {
                    display: block;
                    align-items: center;
                    flex-direction: unset;
                    height: auto;
                    padding-bottom: 10px;
                    margin-bottom: 15px;

                    .form {
                        width: 100%;
                        display: block;
                        padding: 15px 20px 15px 20px;

                        form {
                            display: block;

                            div.form-control-div {
                                margin-top: 15px;
                                display: flex;
                                align-items: center;

                                input.geoPostcode {
                                    width: 100%;
                                }

                                select.searchType {
                                    width: 100%;
                                }
                            }

                            div.btns {
                                display: block;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width:(@smallPhoneWidth)) {

                .borderdBox {
                    width: 100%;

                    .form {
                        width: 100%;
                        display: block;

                        form {
                            display: block;

                            div.form-control-div {
                                width: 100%;

                                input.geoPostcode {
                                    width: 100%;
                                }

                                select.searchType {
                                    width: 100%;
                                }

                                label.geoPostcode {
                                    display: none;
                                }

                                label.searchType {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .field-visibility {
        .dynalabel:first-child {
            margin-top: initial !important;
        }
    }
}
